import { Component } from '@angular/core';
import { Video } from 'src/app/models/video.model';

@Component({
    selector: 'app-flu-and-vaccination',
    templateUrl: './flu-and-vaccination.component.html',
    styleUrls: ['./flu-and-vaccination.component.scss'],
})
export class FluAndVaccinationComponent {
    constructor() {}

    videos: Video[] = [
        {
            topic: 'Discovering Flu and Vaccination',
            text: 'Learn more about the influenza virus, the disease and how to prevent it with vaccination.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '3.08',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_1_Discovering%20Flu%20and%20Vaccination.mp4?alt=media&token=c84380dd-ad1d-47e7-b27b-0d27a7b3127a',
            poster: 'assets/img/flu-and-vaccination/1.Discovering Flu and Vaccination.png',
            videoNumber: 1,
        },
        {
            topic: 'Flu and Vaccination: Focus on the workplace',
            text: 'Workers can have a higher risk of getting sick by influenza: Check out the benefits of vaccination in the workplace setting.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.45',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_2_Flu%20and%20Vaccination%20-%20focus%20on%20the%20workplace.mp4?alt=media&token=02cb9532-4eef-4d84-8940-cb4a1a124839',
            poster: 'assets/img/flu-and-vaccination/2.Flu and Vaccination - focus on the workplac.png',
            videoNumber: 2,
        },
        {
            topic: 'Flu and Vaccination: A choice of social sensitivity',
            text: 'Learn why and how to protect your loved ones and the community from influenza with effective preventive measures.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.43',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_3_Flu%20and%20Vaccination%20-%20a%20choice%20of%20social%20sensitivity.mp4?alt=media&token=59d7a221-e0a1-47f4-87b1-2831a9d801f3',
            poster: 'assets/img/flu-and-vaccination/3.Flu and Vaccination - a choice of social sensitivity.png',
            videoNumber: 3,
        },
        {
            topic: 'Flu and Prevention: Focus on pregnant women and infants',
            text: 'Flu can severely impact pregnant women and their newborns below 6 months of age: Learn more on how to protect them effectively.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.34',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_4_Flu%20and%20Prevention%20-%20Focus%20on%20pregnant%20women%20and%20infants.mp4?alt=media&token=4e6e7430-aaf4-4ac0-8acf-662f5d7b7d20',
            poster: 'assets/img/flu-and-vaccination/4.Flu and Prevention - Focus on pregnant women and infants.png',
            videoNumber: 4,
        },
        {
            topic: 'Flu and Prevention: Focus on Vulnerable Children',
            text: 'Kids are more vulnerable to influenza: check here flu impact on children and how to safeguard the younger population.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.36',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_5_Flu%20and%20Prevention%20-%20Focus%20on%20Vulnerable%20Children.mp4?alt=media&token=3d2c1466-381d-4705-8fee-7708721d4bf7',
            poster: 'assets/img/flu-and-vaccination/5.Flu and Prevention - Focus on Vulnerable Children.png',
            videoNumber: 5,
        },
        {
            topic: 'Flu and Prevention: Focus on Older People',
            text: 'Know more about the reasons and the effective measures to protect elderly people from flu.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.42',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_6_Flu%20and%20Prevention%20-%20Focus%20on%20Older%20People.mp4?alt=media&token=3ff66e1a-c613-47cf-a211-c7549653432f',
            poster: 'assets/img/flu-and-vaccination/6.Flu and Prevention - Focus on Older People.png',
            videoNumber: 6,
        },
        {
            topic: 'Flu and Prevention: Focus on people with chronic conditions',
            text: 'People suffering from chronic cardio-metabolic conditions, such as diabetes, or previous heart attack, are at higher risk of complications if infected by influenza: Learn more on how to prevent flu or worsening of underlying medical conditions.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.56',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_7_At%20risk%20groups%20-%20adults%20with%20chronic%20conditions.mp4?alt=media&token=c30ac7e7-a0ff-4de8-9c5b-1d4f2a5f8e08',
            poster: 'assets/img/flu-and-vaccination/7.At risk groups - adults with chronic conditions.png',
            videoNumber: 7,
        },
        {
            topic: 'Flu and Prevention: Focus on people with respiratory conditions',
            text: 'People with asthma or COPD have a higher risk of complications due to flu: Check out the benefits of vaccination in this at-risk population.',
            bullets: [
                'Click the play button on the bottom left to continue',
                'Click on the square icon to view in full screen',
            ],
            videoLength: '2.54',
            url: 'https://firebasestorage.googleapis.com/v0/b/patient-academy.appspot.com/o/flu-and-vaccination%2FIAPO_8_Flu%20and%20Prevention%20-%20Focus%20on%20people%20with%20respiratory%20conditions.mp4?alt=media&token=d8fc41e3-ccf3-463d-92a6-8304d2f52081',
            poster: 'assets/img/flu-and-vaccination/8.Flu and Prevention - Focus on people with respiratory conditions.png',
            videoNumber: 8,
        },
    ];
}

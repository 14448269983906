import { NgModule } from '@angular/core';
import { AboutComponent } from './components/pages/about/about.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { DoctorComponent } from './components/pages/doctor/doctor.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AtopicDermatitasComponent } from './components/pages/modules/atopic-demermatitis/atopic-dermatitis.component';
import { PatientMessagesComponent } from './components/pages/patient-messages/patient-messages.component';
import { ModulesComponent } from './components/pages/modules/modules.component';
import { FaqAtopicDermatitisComponent } from './components/pages/faqs/faq-atopic-dermatitis/faq-atopic-dermatitis.component';
import { FaqMentalHealthComponent } from './components/pages/faqs/faq-mental-health/faq-mental-health.component';
import { FaqHepatitisComponent } from './components/pages/faqs/faq-hepatitis/faq-hepatitis.component';
import { FaqCardiovascularComponent } from './components/pages/faqs/faq-cardiovascular/faq-cardiovascular.component';
import { FaqRespiratoryComponent } from './components/pages/faqs/faq-respiratory/faq-respiratory.component';
import { FaqOncologyComponent } from './components/pages/faqs/faq-oncology/faq-oncology.component';
import { FaqPainComponent } from './components/pages/faqs/faq-pain/faq-pain.component';
import { FaqDigitalHealthComponent } from './components/pages/faqs/faq-digital-health/faq-digital-health.component';
import { SupportGroupAtopicDermatitisComponent } from './components/pages/support-groups/support-group-atopic-dermatitis/support-group-atopic-dermatitis.component';
import { HomeComponent } from './components/pages/home/home.component';
import { TechnicalSupportComponent } from './components/pages/technical-support/technical-support.component';
import { RouterModule, Routes } from '@angular/router';
import { ResourcesComponent } from './components/pages/resources/resources.component';
import { SupportGroupMentalHealthComponent } from './components/pages/support-groups/support-group-mental-health/support-group-mental-health.component';
import { MentalHealthComponent } from './components/pages/modules/mental-health/mental-health.component';
import { CardioVascularDiseaseComponent } from './components/pages/modules/cardiovascular-disease/cardiovascular-disease.component';
import { SupportGroupCardiovascularDiseaseComponent } from './components/pages/support-groups/support-group-cardiovascular-disease/support-group-cardiovascular-disease.component';
import { CardioVascularDiseaseChinaComponent } from './components/pages/modules/cardiovascular-disease-china/cardiovascular-disease-china.component';
import { RespiratoryDiseaseComponent } from './components/pages/modules/respiratory-disease/respiratory-disease.component';
import { SupportGroupRespiratoryComponent } from './components/pages/support-groups/support-group-respiratory/support-group-respiratory.component';
import { LanguageResolver } from './language.resolver';
import { LiverDiseaseComponent } from './components/pages/modules/liver-disease/liver-disease.component';
import { SupportGroupLiverDiseaseComponent } from './components/pages/support-groups/support-group-liver-disease/support-group-liver-disease.component';
import { PainComponent } from './components/pages/modules/pain/pain.component';
import { SupportGroupPainComponent } from './components/pages/support-groups/support-group-pain/support-group-pain.component';
import { SharedDecisionMakingComponent } from './components/pages/modules/shared-decision-making/shared-decision-making.component';
import { AppLinkComponent } from './components/pages/app/app-link.component';
import { OphthalmologyComponent } from './components/pages/modules/ophthalmology/ophthalmology.component';
import { UrologyComponent } from './components/pages/modules/urology/urology.component';
import { FluAndVaccinationComponent } from './components/pages/modules/flu-and-vaccintation/flu-and-vaccination.component';

const routes: Routes = [
    { path: '', component: HomeComponent, resolve: { lang: LanguageResolver } },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'technical-support', component: TechnicalSupportComponent },
    { path: 'app', component: AppLinkComponent },
    { path: 'modules', component: ModulesComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'login', component: LoginComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-condition', component: TermsConditionComponent },
    { path: 'faq-atopic-dermatitis', component: FaqAtopicDermatitisComponent },
    { path: 'faq-mental-health', component: FaqMentalHealthComponent },
    { path: 'faq-hepatitis', component: FaqHepatitisComponent },
    { path: 'faq-cardiovascular', component: FaqCardiovascularComponent },
    { path: 'faq-respiratory', component: FaqRespiratoryComponent },
    { path: 'faq-oncology', component: FaqOncologyComponent },
    { path: 'faq-pain', component: FaqPainComponent },
    { path: 'faq-digital-health', component: FaqDigitalHealthComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services-details', component: ServicesDetailsComponent },
    { path: 'doctor', component: DoctorComponent },
    { path: 'doctor-details', component: DoctorDetailsComponent },
    { path: 'resources', component: ResourcesComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'links', component: ContactComponent },
    { path: 'atopic-dermatitis', component: AtopicDermatitasComponent },
    { path: 'mental-health', component: MentalHealthComponent },
    { path: 'respiratory-disease', component: RespiratoryDiseaseComponent },
    { path: 'liver-disease', component: LiverDiseaseComponent },
    { path: 'cardiovascular-disease', component: CardioVascularDiseaseComponent },
    { path: 'pain', component: PainComponent },
    { path: 'ophthalmology', component: OphthalmologyComponent },
    { path: 'urology', component: UrologyComponent },
    { path: 'cardiovascular-disease-china', component: CardioVascularDiseaseChinaComponent },
    { path: 'patient-stories', component: PatientMessagesComponent },
    {
        path: 'support-group-atopic-dermatitis',
        component: SupportGroupAtopicDermatitisComponent,
    },
    {
        path: 'support-group-mental-health',
        component: SupportGroupMentalHealthComponent,
    },
    {
        path: 'support-group-cardiovascular-disease',
        component: SupportGroupCardiovascularDiseaseComponent,
    },
    {
        path: 'support-group-respiratory',
        component: SupportGroupRespiratoryComponent,
    },
    {
        path: 'support-group-liver-disease',
        component: SupportGroupLiverDiseaseComponent,
    },
    {
        path: 'support-group-pain',
        component: SupportGroupPainComponent,
    },
    {
        path: 'shared-decision-making',
        component: SharedDecisionMakingComponent,
    },
    {
        path: 'flu-and-vaccination',
        component: FluAndVaccinationComponent,
    },
    { path: '**', component: HomeComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component } from '@angular/core';
import { LanguageService } from '../../language/language.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
    showRespiratory: boolean;
    showLiverDisease: boolean;
    showPain: boolean;
    showOphthalmology: boolean;
    showUrology: boolean;
    showSharedDecisionMaking: boolean;
    showFluAndVaccination: boolean;
    showApp: boolean;

    constructor(private languageService: LanguageService) {
        this.languageService.showRespiratory$.subscribe((visible) => {
            this.showRespiratory = visible;
        });
        this.languageService.showLiverDisease$.subscribe((visible) => {
            this.showLiverDisease = visible;
        });
        this.languageService.showPain$.subscribe((visible) => {
            this.showPain = visible;
        });
        this.languageService.showOphthalmology$.subscribe((visible) => {
            this.showOphthalmology = visible;
        });
        this.languageService.showUrology$.subscribe((visible) => {
            this.showUrology = visible;
        });
        this.languageService.showSharedDecisionMaking$.subscribe((visible) => {
            this.showSharedDecisionMaking = visible;
        });
        this.languageService.showApp$.subscribe((visible) => {
            this.showApp = visible;
        });
        this.languageService.showFluAndVaccination$.subscribe((visible) => {
            this.showFluAndVaccination = visible;
        });
    }

    setLanguage(event: Event, lang: string): void {
        console.log('setLanguage', lang);
        this.languageService.setLanguage(lang);
        event.stopPropagation();
        event.preventDefault();
    }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private defaultLanguage = 'en';
    private language = new BehaviorSubject('en');
    private showRespiratory = new BehaviorSubject(false);
    private showLiverDisease = new BehaviorSubject(false);
    private showPain = new BehaviorSubject(false);
    private showOphthalmology = new BehaviorSubject(false);
    private showUrology = new BehaviorSubject(false);
    private showSharedDecisionMaking = new BehaviorSubject(false);
    private showFluAndVaccination = new BehaviorSubject(false);
    private showApp = new BehaviorSubject(false);

    public availableLangs = [
        {
            name: 'English',
            code: 'en',
            flag: 'flag-icon-za',
        },
        {
            name: 'Spanish',
            code: 'es',
            flag: 'flag-icon-ng',
        },
        {
            name: 'Mandarin',
            code: 'cn',
            flag: 'flag-icon-ng',
        },
    ];

    currentLanguage = this.language.asObservable();
    showRespiratory$ = this.showRespiratory.asObservable();
    showLiverDisease$ = this.showLiverDisease.asObservable();
    showPain$ = this.showPain.asObservable();
    showOphthalmology$ = this.showOphthalmology.asObservable();
    showUrology$ = this.showOphthalmology.asObservable();
    showSharedDecisionMaking$ = this.showSharedDecisionMaking.asObservable();
    showFluAndVaccination$ = this.showFluAndVaccination.asObservable();
    showApp$ = this.showApp.asObservable();

    constructor(private translate: TranslateService) {
        // Initialize showRespiratory based on initial language
        this.showRespiratory.next(this.language.getValue() === 'en');
        this.showLiverDisease.next(this.language.getValue() === 'en');
        this.showPain.next(this.language.getValue() === 'en');
        this.showOphthalmology.next(this.language.getValue() === 'en');
        this.showUrology.next(this.language.getValue() === 'en');
        this.showSharedDecisionMaking.next(this.language.getValue() === 'en');
        this.showFluAndVaccination.next(this.language.getValue() === 'en');
        this.showApp.next(this.language.getValue() === 'en');
    }

    setLanguage(language: string): void {
        if (!language) {
            language = this.defaultLanguage;
        }
        this.language.next(language);
        this.showRespiratory.next(language === 'en');
        this.showLiverDisease.next(language === 'en');
        this.showPain.next(language === 'en');
        this.showOphthalmology.next(language === 'en');
        this.showUrology.next(language === 'en');
        this.showSharedDecisionMaking.next(language === 'en');
        this.showFluAndVaccination.next(language === 'en');
        this.showApp.next(language === 'en');
        this.translate.use(language);
    }

    getLanguage() {
        return this.currentLanguage;
    }
}

<div class="page-title-area page-banner-home2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <img class="banner" [src]="'home-banner' | translate" alt="About" />
                <!-- <h2 >Developed for the patient with the patient in mind</h2> -->
                <!-- <ul>
                    <li><a  routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li >Faq</li>
                </ul> -->
            </div>
        </div>
    </div>
</div>

<div class="home-slider home-slider-two owl-theme owl-carousel">
    <div class="about-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-item">
                        <div class="about-left">
                            <img src="assets/img/banner/Patient-Acad_logo.jpg" alt="About" />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-item about-right">
                        <img src="assets/img/home-one/home-bg5.png" alt="About" />

                        <h2>{{ 'DevelopedForPatient' | translate }}</h2>
                        <p>
                            {{ 'Vision' | translate }}
                        </p>

                        <ul>
                            <li><i class="icofont-check-circled"></i>{{ 'BrowseOurWebsite' | translate }}</li>
                            <li><i class="icofont-check-circled"></i>{{ 'ChooseContent' | translate }}</li>
                            <li><i class="icofont-check-circled"></i>{{ 'ViewContent' | translate }}</li>
                        </ul>
                        <a routerLink="/about">{{ 'Learn More' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Horizontal scrolling banners -->
    <app-banner *ngFor="let banner of banners; trackBy: trackByTopic" [topic]="banner.topic" [text]="banner.text"
        [bullets]="banner.bullets" [image]="banner.image" [buttonUrl]="banner.buttonUrl"
        [buttonText]="banner.buttonText"></app-banner>
</div>

<!-- vertical banners - one below each other -->
<app-banner *ngFor="let banner of banners" [topic]="banner.topic" [text]="banner.text" [bullets]="banner.bullets"
    [image]="banner.image" [buttonUrl]="banner.buttonUrl" [buttonText]="banner.buttonText"></app-banner>

<section class="testimonial-area ptb-100">
    <div id="endorsements" class="container">
        <div class="testimonial-wrap">
            <h2>{{ 'Endorsements' | translate }}</h2>

            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="assets/img/endorsements/Thomas Luger.jpg" alt="Testimonial" />
                    <h3>{{ 'Professor' | translate }} Thomas Luger</h3>
                    <p>
                        {{ 'Thank you for the very nice educational program which I am happy to endorse' | translate }}
                    </p>
                </div>
                <div class="testimonial-item">
                    <img src="assets/img/endorsements/sripal-bangalore.jpg" alt="Testimonial" />
                    <h3>{{ 'Professor' | translate }} Sripal Bangalore</h3>
                    <p>{{ 'This site is great and I am happy to endorse' | translate }}</p>
                </div>
                <div class="testimonial-item">
                    <img src="assets/img/endorsements/Dr-Naim-Aoun.png" alt="Testimonial" />
                    <h3>{{ 'Dr.' | translate }} Naim Aoun</h3>
                    <p>
                        {{
                            'A valuable patient education site for anyone seeking reliable and unbiased content tailored specifically to their medical needs.'
                                | translate
                        }}
                    </p>
                </div>
                <div *ngIf="(languageService.getLanguage() | async) === 'en'" class="testimonial-item">
                    <img src="assets/img/endorsements/ali-mobasheri.jpg" alt="Testimonial" />
                    <h3>{{ 'Professor' | translate }} Ali Mobasheri</h3>
                    <p>
                        {{
                            'The educational materials on the Viatris Pain Academy website have been developed by healthcare professional with patients in mind to facilitate shared decision making and integrate digital care programs for personalised medicine.'
                                | translate
                        }}
                    </p>
                </div>
                <div *ngIf="(languageService.getLanguage() | async) === 'en'" class="testimonial-item">
                    <img src="assets/img/endorsements/daniel-clauw.jpg" alt="Testimonial" />
                    <h3>{{ 'Professor' | translate }} Daniel Clauw</h3>
                    <p>
                        {{
                            'These programs for patients are simple and easy to understand and contain important information.'
                                | translate
                        }}
                    </p>
                </div>
                <div *ngIf="(languageService.getLanguage() | async) === 'en'" class="testimonial-item">
                    <img src="assets/img/endorsements/solomon_tesfaye.jpg" alt="Testimonial" />
                    <h3>{{ 'Professor' | translate }} Solomon Tesfaye</h3>
                    <p>
                        {{ 'I endorse Patient Academy' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container pt-100">
        <img class="logo-center" src="assets/img/viatris-logo-logos.jpeg" alt="Testimonial" />
    </div>
</section>

<div class="counter-area counter-area-three">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-12 col-lg-12">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h4>
                        <span class="counter">{{ 'Developed for the patient with patients in mind' | translate }}</span>
                    </h4>
                    <p>{{ 'A collaboration between Viatris and IAPO' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>